@font-face {
	font-family: "Achemine";
	src: url("achemine_normal.eot#iefix") format("embedded-opentype"),
	url("achemine_normal.woff") format("woff"),
	url("achemine_normal.ttf") format("truetype"),
	url("achemine_normal.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Achemine";
	src: url("achemine_bold.eot#iefix") format("embedded-opentype"),
	url("achemine_bold.woff") format("woff"),
	url("achemine_bold.ttf") format("truetype"),
	url("achemine_bold.svg") format("svg");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Achemine";
	src: url("achemine_extrabold.eot#iefix") format("embedded-opentype"),
	url("achemine_extrabold.woff") format("woff"),
	url("achemine_extrabold.ttf") format("truetype"),
	url("achemine_extrabold.svg") format("svg");
	font-weight: 800;
	font-style: normal;
}


@font-face {
	font-family: "Achemine";
	src: url("achemin_italic.eot#iefix") format("embedded-opentype"),
	url("achemin_italic.woff") format("woff"),
	url("achemin_italic.ttf") format("truetype"),
	url("achemin_italic.svg#Achemine-Italic") format("svg");
	font-weight: normal;
	font-style: italic;
}