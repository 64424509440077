@use "@material/icon-button/icon-button";
@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/data-table/data-table";
@use "@material/chips/styles";

@include icon-button.core-styles;
@include data-table.core-styles;

@import "material-icons/iconfont/material-icons.scss";
@import "./assets/fonts/Avenir/style.css";
@import "./assets/fonts/Achemine/style.css";

body {
    background-color: black;
    color: white;
    font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.top-bar {
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    background-color: #003a79;
}

.full-width {
    width: 100%;
}